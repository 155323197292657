<template>
  <div class="tab-detail">
    <!-- <h3 class="AAA">회원 이름  : {{this.userName}}</h3> -->
    <div class="detail evaluate" v-if="listType === 0">
      <div class="member-info-set">
        <div class="header">
          <div class="item">회원명</div>
          <div class="item">평가일</div>
        </div>
        <div class="content">
          <div class="item">
            <p>{{ this.userName }}</p>
          </div>
          <div class="item">
            <div class="select-wrap">
              <v-select
                :searchable="false"
                :options="result.structure.dayList"
                @input="dayPick('all')"
                v-model="structure.selectedDay"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
      <!-- <section>
        <div class="header">
          <div class="select-wrap">
            <v-select
              :searchable="false"
              :options="result.structure.dayList"
              @input="dayPick('all')"
              v-model="structure.selectedDay"
            ></v-select>
          </div>
        </div>
      </section> -->
      <section
        v-if="
          structureList.list !== undefined &&
            structureList.list !== null &&
            structureList.list.length > 0
        "
      >
        <div class="header">
          <h3 class="title">삼킴 구조와 기능</h3>
          <div class="select-wrap">
            <!-- <v-select
              :searchable="false"
              :options="result.structure.dayList"
              @input="dayPick('structure')"
              v-model="structure.selectedDay"
            ></v-select> -->
          </div>
        </div>
        <div class="content structure1">
          <h5 class="title">기도 삽관 등 상세정보</h5>
          <div class="list-wrap">
            <div
              class="list"
              v-for="(item, parentIndex) in structureList.list.slice(0, 5)"
              :key="parentIndex + 'A'"
            >
              <div class="l-header">
                <div class="item">
                  <h6 v-text="item.questionText" />
                </div>
              </div>
              <div class="l-content">
                <div class="item" v-if="parentIndex != 3">
                  <span
                    v-for="(answerItem, childIndex) in item.answerList"
                    :key="childIndex + 'B'"
                    v-text="
                      answerItem.answerText +
                        ((parentIndex == 4) &
                          (item.selectedText.trim() === answerItem.answerText.trim()) &&
                        item.etcText != '' &&
                        item.etcText != null
                          ? ' : ' + item.etcText
                          : '')
                    "
                    :class="{
                      selected: item.selectedText.trim() === answerItem.answerText.trim()
                    }"
                  ></span>
                </div>
                <div class="item" v-else>
                  <span
                    v-text="item.selectedText + '회'"
                    :class="{
                      selected: item.selectedText > 0
                    }"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content structure2">
          <h5 class="title">반응 척도 등 상세정보</h5>
          <div class="list-wrap" v-if="structureList.list.length > 5">
            <div
              class="list"
              v-for="(item, parentIndex) in structureList.list.slice(5, structureList.list.length)"
              :key="parentIndex + 'A2'"
            >
              <div class="l-header">
                <div class="item">
                  <h6 v-text="item.questionText" />
                </div>
              </div>
              <div class="l-content">
                <div class="item">
                  <span
                    v-for="(answerItem, childIndex) in item.answerList"
                    :key="childIndex + 'B2'"
                    v-text="answerItem.answerText"
                    :class="{ selected: item.selectedText.trim() === answerItem.answerText.trim() }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        v-if="waterList.list !== undefined && waterList.list !== null && waterList.list.length > 2"
      >
        <div class="header">
          <h3 class="title">물 삼키기</h3>
          <div class="select-wrap">
            <!-- <v-select
              :searchable="false"
              :options="result.water.dayList"
              @input="dayPick('water')"
              v-model="water.selectedDay"
            ></v-select> -->
          </div>
        </div>
        <div class="content water plus">
          <h5 class="title">물 3ml</h5>
          <div class="list-wrap">
            <div class="list complex">
              <div class="c-header">
                <h3>아~</h3>
              </div>
              <div class="c-content">
                <div class="box">
                  <div class="l-header">
                    <div class="item">
                      <h6>전</h6>
                    </div>
                  </div>
                  <div class="l-content">
                    <div class="item">
                      <div class="img-wrap" @click="voiceLink('first', 'before')">
                        <img src="@/assets/images/util/icon_sound.svg" />
                      </div>
                      <span v-text="audioList[0].beforeAudioLength"></span>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="l-header">
                    <div class="item">
                      <h6>후</h6>
                    </div>
                  </div>
                  <div class="l-content">
                    <div class="item">
                      <div class="img-wrap" @click="voiceLink('first', 'after')">
                        <img src="@/assets/images/util/icon_sound.svg" />
                      </div>
                      <span v-text="audioList[0].afterAudioLength"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="list"
              v-for="(item, parentIndex) in waterList.list.slice(0, 3)"
              :key="parentIndex + 'B'"
            >
              <div class="l-header">
                <div class="item">
                  <h6 v-text="item.questionText" />
                </div>
              </div>
              <div class="l-content">
                <div class="item">
                  <span
                    v-for="(answerItem, childIndex) in item.answerList"
                    :key="childIndex + 'B'"
                    v-text="answerItem.answerText"
                    :class="{ selected: item.selectedText.trim() === answerItem.answerText.trim() }"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content water plus" v-if="waterList.list.length > 5">
          <h5 class="title">물 9ml</h5>
          <div class="list-wrap">
            <div class="list complex">
              <div class="c-header">
                <h3>아~</h3>
              </div>
              <div class="c-content">
                <div class="box">
                  <div class="l-header">
                    <div class="item">
                      <h6>전</h6>
                    </div>
                  </div>
                  <div class="l-content">
                    <div class="item">
                      <div class="img-wrap" @click="voiceLink('second', 'before')">
                        <img src="@/assets/images/util/icon_sound.svg" />
                      </div>
                      <span v-text="audioList[1].beforeAudioLength"></span>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="l-header">
                    <div class="item">
                      <h6>후</h6>
                    </div>
                  </div>
                  <div class="l-content">
                    <div class="item">
                      <div class="img-wrap" @click="voiceLink('second', 'after')">
                        <img src="@/assets/images/util/icon_sound.svg" />
                      </div>
                      <span v-text="audioList[1].afterAudioLength"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="list"
              v-for="(item, parentIndex) in waterList.list.slice(3, 6)"
              :key="parentIndex + 'B2'"
            >
              <div class="l-header">
                <div class="item">
                  <h6 v-text="item.questionText" />
                </div>
              </div>
              <div class="l-content">
                <div class="item">
                  <span
                    v-for="(answerItem, childIndex) in item.answerList"
                    :key="childIndex + 'B'"
                    v-text="answerItem.answerText"
                    :class="{ selected: item.selectedText.trim() === answerItem.answerText.trim() }"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content water" v-if="waterList.list.length > 8">
          <h5 class="title">물 90ml</h5>
          <div class="list-wrap">
            <div
              class="list"
              v-for="(item, parentIndex) in waterList.list.slice(6, waterList.list.length)"
              :key="parentIndex + 'B2'"
            >
              <div class="l-header">
                <div class="item">
                  <h6 v-text="item.questionText" />
                </div>
              </div>
              <div class="l-content">
                <div class="item">
                  <span
                    v-for="(answerItem, childIndex) in item.answerList"
                    :key="childIndex + 'B'"
                    v-text="answerItem.answerText"
                    :class="{ selected: item.selectedText.trim() === answerItem.answerText.trim() }"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        v-if="solidList.list !== undefined && solidList.list !== null && solidList.list.length > 2"
      >
        <div class="header">
          <h3 class="title">고체 씹기</h3>
          <div class="select-wrap">
            <!-- <v-select
              :searchable="false"
              :options="result.solid.dayList"
              @input="dayPick('solid')"
              v-model="solid.selectedDay"
            ></v-select> -->
          </div>
        </div>
        <div class="content solid">
          <div class="header-grid">
            <h5 class="title">보통 속도</h5>
            <h5 class="title">최대 속도</h5>
            <h5 class="title">턱 움직임</h5>
          </div>
          <div class="list-wrap flex">
            <div
              class="list"
              v-for="(item, parentIndex) in solidList.list.slice(0, 2)"
              :key="parentIndex + 'B'"
            >
              <div
                class="box"
                v-for="(answerItem, answerIndex) in item.answerList"
                :key="answerIndex + 'C'"
              >
                <div class="l-header">
                  <div class="item">
                    <h6 v-text="answerItem.answerText" />
                  </div>
                </div>
                <div class="l-content">
                  <div class="item">
                    <span v-text="answerItem.selected"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="list">
              <div class="box">
                <div class="l-header">
                  <div class="item">
                    <h6 v-text="solidList.list[2].questionText"></h6>
                  </div>
                </div>
                <div class="l-content">
                  <div class="item">
                    <span
                      v-for="(inner, innerIndex) in solidList.list[2].answerList"
                      :key="innerIndex + 'A'"
                      v-text="inner.answerText"
                      :class="{ selected: innerIndex === solidList.list[2].selected }"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="vocalizeList.list !== undefined && vocalizeList.list !== null">
        <div class="header">
          <h3 class="title">~아/퍼/터/커</h3>
          <div class="select-wrap">
            <!-- <v-select
              :searchable="false"
              :options="result.vocalize.dayList"
              @input="dayPick('vocalize')"
              v-model="vocalize.selectedDay"
            ></v-select> -->
          </div>
        </div>
        <div class="content vocalize1">
          <div class="header-grid">
            <h5 class="title">터</h5>
            <h5 class="title">아</h5>
          </div>
          <div class="list-wrap flex">
            <div
              class="list"
              v-for="(item, parentIndex) in vocalizeList.list.slice(0, 2)"
              :key="parentIndex + 'D'"
            >
              <!-- 11 -->
              <div
                class="box"
                v-for="(answerItem, answerIndex) in item.answerList"
                :key="answerIndex + 'C'"
              >
                <div class="l-header" v-if="item.question == 1 || item.question == 2">
                  <div class="item">
                    <h6 v-text="answerItem.answerText" />
                  </div>
                </div>
                <div class="l-content" v-if="item.question == 1 || item.question == 2">
                  <div class="item">
                    <div
                      class="img-wrap"
                      @click="voiceLink2('first', parentIndex)"
                      v-if="answerIndex === 0 && item.audio !== null"
                    >
                      <img src="@/assets/images/util/icon_sound.svg" />
                    </div>
                    <span v-text="answerItem.selected"></span>
                  </div>
                </div>
              </div>
              <!-- 22 -->
            </div>
          </div>
        </div>
        <div class="content vocalize2">
          <div class="header-grid">
            <h5 class="title">퍼</h5>
            <h5 class="title">커</h5>
          </div>
          <div class="list-wrap flex">
            <div
              class="list"
              v-for="(item, parentIndex) in vocalizeList.list.slice(2, 4)"
              :key="parentIndex + 'D'"
            >
              <!-- 11 -->
              <div
                class="box"
                v-for="(answerItem, answerIndex) in item.answerList"
                :key="answerIndex + 'C'"
              >
                <div class="l-header" v-if="item.question == 3 || item.question == 4">
                  <div class="item">
                    <h6 v-text="answerItem.answerText" />
                  </div>
                </div>
                <div class="l-content" v-if="item.question == 3 || item.question == 4">
                  <div class="item">
                    <div
                      class="img-wrap"
                      @click="voiceLink2('second', parentIndex)"
                      v-if="answerIndex === 0 && item.audio !== null"
                    >
                      <img src="@/assets/images/util/icon_sound.svg" />
                    </div>
                    <span v-text="answerItem.selected"></span>
                  </div>
                </div>
              </div>
              <!-- 22 -->
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="detail survey" v-if="listType === 1">
      <div class="sub-wrap" v-if="!isEmpty">
        <div class="member-info-set">
          <div class="header">
            <div class="item">회원명</div>
            <div class="item">평가일</div>
          </div>
          <div class="content">
            <div class="item">
              <p>{{ this.userName }}</p>
            </div>
            <div class="item">
              <div class="select-wrap">
                <v-select
                  :searchable="false"
                  :options="result.survey.dayList"
                  @input="dayPick('survey')"
                  v-model="survey.selectedDay"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div class="header">
            <!-- <div class="select-wrap">
            <v-select
              :searchable="false"
              :options="result.survey.dayList"
              @input="dayPick('survey')"
              v-model="survey.selectedDay"
            ></v-select>
          </div> -->
          </div>
          <div class="content">
            <div class="header-grid">
              <h5 class="title">주제</h5>
              <h5 class="title">순서</h5>
              <h5 class="title">설문내용</h5>
              <h5 class="title">답변</h5>
            </div>
            <div class="list-complex">
              <div class="topic">
                <h6 v-text="functionList.category" />
              </div>
              <div class="list-wrap flex">
                <div
                  class="list"
                  v-for="(Item, parentIndex) in functionList.list"
                  :key="parentIndex + 'A'"
                >
                  <div class="l-order">
                    <span v-text="parentIndex + 1" />
                  </div>
                  <div class="l-header">
                    <div class="item">
                      <h6 v-text="Item.questionText" />
                    </div>
                  </div>
                  <div class="l-content">
                    <div class="item">
                      <span
                        v-for="(answerItem, childIndex) in Item.answerList"
                        :key="childIndex + 'B'"
                        v-text="answerItem.answerText"
                        :class="{
                          selected: Item.selectedText.trim() === answerItem.answerText.trim()
                        }"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="list-complex">
              <div class="topic">
                <h6 v-text="solidLifeList.category" />
              </div>
              <div class="list-wrap flex">
                <div
                  class="list"
                  v-for="(Item, parentIndex) in solidLifeList.list"
                  :key="parentIndex + 'C'"
                >
                  <div class="l-order">
                    <span v-text="parentIndex + 1" />
                  </div>
                  <div class="l-header">
                    <div class="item">
                      <h6 v-text="Item.questionText" />
                    </div>
                  </div>
                  <div class="l-content">
                    <div class="item">
                      <span
                        v-for="(answerItem, childIndex) in Item.answerList"
                        :key="childIndex + 'D'"
                        v-text="answerItem.answerText"
                        :class="{
                          selected: Item.selectedText.trim() === answerItem.answerText.trim()
                        }"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="list-complex">
              <div class="topic">
                <h6 v-text="waterLifeList.category" />
              </div>
              <div class="list-wrap">
                <div
                  class="list"
                  v-for="(Item, parentIndex) in waterLifeList.list"
                  :key="parentIndex + 'C'"
                >
                  <div class="l-order">
                    <span v-text="parentIndex + 1" />
                  </div>
                  <div class="l-header">
                    <div class="item">
                      <h6 v-text="Item.questionText" />
                    </div>
                  </div>
                  <div class="l-content">
                    <div class="item">
                      <span
                        v-for="(answerItem, childIndex) in Item.answerList"
                        :key="childIndex + 'D'"
                        v-text="answerItem.answerText"
                        :class="{
                          selected: Item.selectedText.trim() === answerItem.answerText.trim()
                        }"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="list-complex">
              <div class="topic">
                <h6 v-text="gdsList.category" />
              </div>
              <div class="list-wrap">
                <div
                  class="list"
                  v-for="(Item, parentIndex) in gdsList.list"
                  :key="parentIndex + 'C'"
                >
                  <div class="l-order">
                    <span v-text="parentIndex + 1" />
                  </div>
                  <div class="l-header">
                    <div class="item">
                      <h6 v-text="Item.questionText" />
                    </div>
                  </div>
                  <div class="l-content">
                    <div class="item">
                      <span
                        v-for="(answerItem, childIndex) in Item.answerList"
                        :key="childIndex + 'D'"
                        v-text="answerItem.answerText"
                        :class="{
                          selected: Item.selectedText.trim() === answerItem.answerText.trim()
                        }"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="empty-zone" v-else>
        <p>삼킴설문이 진행되지 않았습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import {
  mapMutations, mapState, mapGetters, mapActions,
} from 'vuex';

export default {
  props: {
    listType: {
      type: Number,
    },
    result: {
      type: Object,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userName: '',
      structure: {
        selectedDay: '',
      },
      water: {
        selectedDay: '',
      },
      solid: {
        selectedDay: '',
      },
      vocalize: {
        selectedDay: '',
      },
      survey: {
        selectedDay: '',
      },
      structureList: {
        type: '',
        list: [],
      },
      waterList: {
        type: '',
        list: [],
      },
      audioList: [],
      solidList: {
        type: '',
        list: [],
      },
      vocalizeList: {
        type: '',
        list: [],
      },
      /** 2탭 */
      functionList: [],
      solidLifeList: [],
      waterLifeList: [],
      gdsList: [],
    };
  },
  mounted() {},
  created() {
    console.log('진입점은?', this.listType);
    this.initDay(this.listType);
  },
  methods: {
    ...mapActions('evaluate', ['FETCH_EVALUATION_DETAIL', 'FETCH_SURVEY_DETAIL']),
    ...mapActions('member', ['FETCH_ACCOUNT_DETAIL']),
    voiceLink2(type, parentIndex) {
      let voiceUrl;
      if (type === 'first') {
        voiceUrl = this.vocalizeList.list[parentIndex].audio;
      } else {
        voiceUrl = this.vocalizeList.list[parentIndex + 2].audio;
      }
      window.open(`https://admin.swallow.pro${voiceUrl}`, '_blank');
    },
    voiceLink(address, order) {
      let voiceUrl;
      if (address === 'first') {
        if (order === 'before') {
          voiceUrl = this.audioList[0].beforeAudio;
        } else if (order === 'after') {
          voiceUrl = this.audioList[0].afterAudio;
        }
      } else if ('second') {
        if (order === 'before') {
          voiceUrl = this.audioList[1].beforeAudio;
        } else if (order === 'after') {
          voiceUrl = this.audioList[1].afterAudio;
        }
      }
      window.open(`https://admin.swallow.pro${voiceUrl}`, '_blank');
    },
    initDay(type) {
      console.log('가져온 타입', type);
      console.log('가져온 리설트', this.result);
      const excu = Object.entries(this.result);
      const keyArray = [];
      const valueArray = [];
      for (const [key, value] of excu) {
        keyArray.push(key);
        valueArray.push(value);
      }
      if (type === 0) {
        if (this.result[keyArray[0]].dayList.length > 0) {
          this.structure.selectedDay = this.result[keyArray[0]].dayList[0];
          this.dayPick('all');
        }

        /*
        for (let i = 0; i < Object.keys(this.result).length; i++) {
          if (i === 0 && this.result[keyArray[i]].dayList.length > 0) {
            this.structure.selectedDay = this.result[keyArray[i]].dayList[0];
            this.dayPick("structure");
          } else if (i === 1 && this.result[keyArray[i]].dayList.length > 0) {
            this.water.selectedDay = this.result[keyArray[i]].dayList[0];
            this.dayPick("water");
          } else if (i === 2 && this.result[keyArray[i]].dayList.length > 0) {
            this.solid.selectedDay = this.result[keyArray[i]].dayList[0];
            this.dayPick("solid");
          } else if (i === 3 && this.result[keyArray[i]].dayList.length > 0) {
            this.vocalize.selectedDay = this.result[keyArray[i]].dayList[0];
            this.dayPick("vocalize");
          }
        }
        */
      } else if (type === 1) {
        for (let i = 0; i < Object.keys(this.result).length; i++) {
          if (i === 0 && this.result[keyArray[i]].dayList.length > 0) {
            this.survey.selectedDay = this.result[keyArray[i]].dayList[0];
            this.dayPick('survey');
          }
        }
      }
    },
    async dayPick(type) {
      let date = this.structure.selectedDay;
      const userid = this.$route.params.code;

      // 이름을 가져온다.
      try {
        this.userName = '-';
        const member_request_param = `?id=${userid}`;
        await this.FETCH_ACCOUNT_DETAIL(member_request_param).then((data) => {
          this.userName = data.response.list[0].answerResult;

          console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCC', data);
        });
      } catch (error) {
        console.log('error : ', error);
      }

      if (type === 'all' || type === 'structure') {
        try {
          this.requestParam = `?userId=${userid}&type=structure&get_type=data&date=${date}`;
          await this.FETCH_EVALUATION_DETAIL(this.requestParam).then((data) => {
            this.structureList = {
              type: 'structure',
              list: data.response.structure.answerList,
            };

            console.log('structure :', this.structureList.list);
          });
        } catch (error) {
          console.log('error:', error);
        }
      }

      if (type === 'all' || type === 'water') {
        try {
          this.requestParam = `?userId=${userid}&type=water&get_type=data&date=${date}`;
          await this.FETCH_EVALUATION_DETAIL(this.requestParam).then((data) => {
            this.waterList = {
              type: 'water',
              list: data.response.water.answerList,
            };
            this.audioList = data.response.water.audioList;
            console.log('water :', this.waterList.list);
          });
        } catch (error) {
          console.log('error:', error);
        }
      }

      if (type === 'all' || type === 'solid') {
        try {
          this.requestParam = `?userId=${userid}&type=solid&get_type=data&date=${date}`;
          await this.FETCH_EVALUATION_DETAIL(this.requestParam).then((data) => {
            this.solidList = {
              type: 'solid',
              list: data.response.solid.answerList,
            };
            console.log('solid :', this.solidList.list);
          });
        } catch (error) {
          console.log('error:', error);
        }
      }

      if (type === 'all' || type === 'vocalize') {
        try {
          this.requestParam = `?userId=${userid}&type=vocalize&get_type=data&date=${date}`;
          await this.FETCH_EVALUATION_DETAIL(this.requestParam).then((data) => {
            this.vocalizeList = {
              type: 'vocalize',
              list: data.response.vocalize.answerList,
            };
            console.log('vocalize :', this.vocalizeList.list);
          });
        } catch (error) {
          console.log('error:', error);
        }
      }

      if (type === 'survey') {
        try {
          date = this.survey.selectedDay;
          this.requestParam = `?userId=${userid}&get_type=data&date=${date}`;
          await this.FETCH_SURVEY_DETAIL(this.requestParam).then((data) => {
            console.log('2-0.survey 선택한 날짜  :', this.survey.selectedDay);
            this.functionList = {
              type: 'function',
              category: data.response.list[0].category,
              list: data.response.list[0].list,
            };

            this.solidLifeList = {
              type: 'solidLife',
              category: data.response.list[1].category,
              list: data.response.list[1].list,
            };

            this.waterLifeList = {
              type: 'waterLife',
              category: data.response.list[2].category,
              list: data.response.list[2].list,
            };

            this.gdsList = {
              type: 'gds',
              category: data.response.list[3].category,
              list: data.response.list[3].list,
            };

            console.log('list: data.response.list0 : ', data.response.list[0].list);
            console.log('list: data.response.list1 : ', data.response.list[1].list);
            console.log('list: data.response.list2 : ', data.response.list[2].list);
            console.log('list: data.response.list3 : ', data.response.list[3].list);
          });
        } catch (error) {
          console.log('error:', error);
        }
      }
    },
  },
  computed: {},
  components: {
    vSelect,
  },
};
</script>
<style lang="scss" scoped>
.empty-zone {
  min-height: 190px;
  padding: 25px;
  display: flex;
  align-items: center;
  p {
    @include initfontKor(
      $font-size: 21px,
      $line-height: 27px,
      $font-family: "AppleSDGothicNeoM00",
      $color: #292a2b,
      $letter-spacing: -0.48px,
      $text-align: left
    );
  }
}
.member-info-set {
  width: 500px;
  margin: 0 0 85px 0;
  .header {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 250px 1fr;
    margin: 0 0 1px 0;
    .item {
      outline: 1px solid #acb3c9;
      background: #f3f3f3;
      padding: 8px 12px;
      @include initfontKor(
        $font-size: 16px,
        $line-height: 26px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
  }
  .content {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 250px 1fr;
    .item {
      padding: 2px 12px;
      outline: 1px solid #acb3c9;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}

.tab-detail {
  padding: 52px 32px;
  .detail {
    section {
      margin: 30px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      .header {
        display: flex;
        margin: 0 0 20px 0;
        h3.title {
          width: 50%;
          background: #fd8b11;
          display: flex;
          align-items: center;
          justify-content: center;
          @include initfontKor(
            $font-size: 21px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #fff,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
        .select-wrap {
          height: 48px;
          width: 50%;
        }
      }
      .content {
        margin: 40px 0 0 0;
        &:first-of-type {
          margin: 0;
        }
        &.plus {
          .list-wrap {
            grid-template-columns: 600px 1fr 1fr 1fr !important;
            .list {
              .l-header {
                .item {
                  h6 {
                    line-height: 65px;
                  }
                }
              }
              .l-content {
                .item {
                  display: flex;
                  align-items: center;
                  .img-wrap {
                    margin: 0 6px 0 0;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    img {
                      max-width: 32px;
                    }
                  }
                }
              }
              &.complex {
                .l-header {
                  .item {
                    h6 {
                      line-height: 32px;
                    }
                  }
                }
                .c-header {
                  background: #f3f3f3;
                  outline: 1px solid #acb3c9;
                  margin: 0 0 1px 0;
                  h3 {
                    @include initfontKor(
                      $font-size: 16px,
                      $line-height: 32px,
                      $font-family: "AppleSDGothicNeoM00",
                      $color: #292a2b,
                      $letter-spacing: -0.6px,
                      $text-align: center
                    );
                  }
                }
                .c-content {
                  display: flex;
                  width: 100%;
                  .box {
                    width: 50%;
                  }
                }
              }
            }
          }
        }
        h5.title {
          width: 100%;
          margin: 0 0 15px 0;
          @include initfontKor(
            $font-size: 22px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: left
          );
        }
        .list-wrap {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          .list {
            .l-header {
              margin: 0 0 1px 0;
              .item {
                background: #f3f3f3;
                padding: 8px 0;
                outline: 1px solid #acb3c9;
                h6 {
                  @include initfontKor(
                    $font-size: 16px,
                    $line-height: 32px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #292a2b,
                    $letter-spacing: -0.6px,
                    $text-align: center
                  );
                }
              }
            }
            .l-content {
              .item {
                display: flex;
                justify-content: center;
                padding: 8px 0;
                outline: 1px solid #acb3c9;
                span {
                  position: relative;
                  padding: 0 6px;
                  @include initfontKor(
                    $font-size: 16px,
                    $line-height: 32px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #292a2b,
                    $letter-spacing: -0.6px,
                    $text-align: center
                  );
                  &.selected {
                    color: #f36900;
                  }
                  &:after {
                    content: "/";
                    color: #292a2b;
                    position: absolute;
                    left: -1px;
                  }
                  &:first-of-type {
                    padding: 0 6px 0 0;
                    &:after {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.tab-detail .detail {
  &.survey {
    section .content {
      margin: 1px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      .header-grid {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        margin: 0 0 1px 0;
        grid-template-columns: 310px 90px 430px 1fr;
        background: #fd8b11;
        h5.title {
          margin: 0;
          text-align: center;
          line-height: 52px;
          outline: 1px solid #292a2b;
          color: #fff;
        }
      }
      .list-complex {
        display: flex;
        .topic {
          width: 310px;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: 1px solid #292a2b;
          margin: 0 1px 0 0;
        }
        .list-wrap {
          width: calc(100% - 311px);
          .list {
            display: grid;
            grid-auto-flow: dense;
            grid-gap: 1px;
            grid-template-columns: 90px 430px 1fr;
            .l-header {
              margin: 0;
              .item {
                background: transparent;
                outline: 1px solid #292a2b;
              }
            }
            .l-order {
              display: flex;
              align-items: center;
              justify-content: center;
              outline: 1px solid #292a2b;
              span {
                display: block;
                @include initfontKor(
                  $font-size: 16px,
                  $line-height: 32px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: #292a2b,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
            .l-content {
              .item {
                outline: 1px solid #292a2b;
              }
            }
          }
        }
      }
    }
  }
  &.evaluate {
    section .content {
      &.structure1 {
        .list-wrap {
          grid-template-columns: 260px 260px 260px 260px 1fr;
        }
      }
      &.structure2 {
        .list-wrap {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
      }
      &.water {
        .list-wrap {
          grid-template-columns: 430px 1fr 1fr;
        }
      }
      &.solid {
        .header-grid {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          margin: 0 0 15px 0;
          grid-template-columns: 430px 430px 1fr;
          h5.title {
            margin: 0 0 0 20px;
            &:first-of-type {
              margin: 0;
            }
          }
        }
        .list-wrap {
          &.flex {
            grid-template-columns: 430px 430px 1fr;
            .list {
              display: grid;
              grid-auto-flow: dense;
              grid-gap: 1px;
              grid-template-columns: 1fr 1fr 1fr;
              margin: 0 0 0 20px;
              &:first-of-type {
                margin: 0;
              }
              &:last-of-type {
                grid-template-columns: inherit;
              }
            }
          }
        }
      }
      &.vocalize1 {
        .header-grid {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          margin: 0 0 15px 0;
          grid-template-columns: 430px 430px 430px 1fr;
          h5.title {
            margin: 0 0 0 20px;
            &:first-of-type {
              margin: 0;
            }
          }
        }
        .list-wrap {
          &.flex {
            grid-template-columns: 430px 430px 430px 1fr;
            .list {
              display: grid;
              grid-auto-flow: dense;
              grid-gap: 1px;
              grid-template-columns: 1fr 1fr 1fr;
              margin: 0 0 0 20px;
              &:first-of-type {
                margin: 0;
              }
              &:last-of-type {
                grid-template-columns: inherit;
              }
              .box {
                .l-content {
                  .item {
                    display: flex;
                    .img-wrap {
                      margin: 0 4px 0 0;
                      cursor: pointer;
                      display: flex;
                      img {
                        max-width: 28px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.vocalize2 {
        .header-grid {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          margin: 0 0 15px 0;
          grid-template-columns: 430px 430px;
          h5.title {
            margin: 0 0 0 20px;
            &:first-of-type {
              margin: 0;
            }
          }
        }
        .list-wrap {
          &.flex {
            grid-template-columns: 430px 430px;
            .list {
              display: grid;
              grid-auto-flow: dense;
              grid-gap: 1px;
              grid-template-columns: 1fr 1fr 1fr;
              margin: 0 0 0 20px;
              &:first-of-type {
                margin: 0;
              }
              .box {
                .l-content {
                  .item {
                    display: flex;
                    .img-wrap {
                      margin: 0 4px 0 0;
                      cursor: pointer;
                      display: flex;
                      img {
                        max-width: 28px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
