<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="video-record">
          <div class="member-info-set" v-if="member.length > 0">
            <div class="header">
              <div class="item">
                <p>회원명</p>
              </div>
            </div>
            <div class="content">
              <div class="item">
                <p v-text="member[0].answerResult" />
              </div>
            </div>
          </div>
          <div
            class="video-wrap"
            v-for="(videoParent, parentIndex) in videoList"
            :key="parentIndex"
          >
            <h3 class="title" v-if="videoParent.detailList.length > 0">
              {{ videoParent.day + "일차" }}
            </h3>
            <div class="notification" v-else>
              <p>저장된 혀 돌리기 영상이 없습니다.</p>
            </div>
            <div class="download-wrap">
              <div
                class="download"
                v-for="(videoChild, childIndex) in videoParent.detailList"
                :key="childIndex"
              >
                <h3>{{ childIndex + 1 + "회" }}</h3>
                <span @click="linkVideo(videoChild.video)">영상조회 및 다운로드</span>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: "삼킴 훈련",
        detail: "삼킴 훈련 리스트",
        detail2: "훈련별 상세정보",
        detail3: "혀 돌리기 영상조회"
      },
      member: [],
      result: {},
      view: {},
      videoList: []
    };
  },
  created() {
    console.log(this.$route.query.userId);

    window.scrollTo(0, 0);
    this.getMember();
    this.getData(this.$route.query.userId);
  },
  methods: {
    ...mapActions("training", ["FETCH_TRAINING_VIDEO", "FETCH_TRAINING_DATE"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    async getData(payload) {
      try {
        await this.FETCH_TRAINING_VIDEO(payload).then(data => {
          console.log("data :", data);
          this.videoList = data.response.list;
        });
      } catch (error) {
        console.log("error :", error);
      }
    },
    dayPick(value) {
      console.log("daypickValue :", value);
      /**
      this.reloadData(value);
       */
    },
    linkVideo(video) {
      console.log("item :", video);
      if (video === null || video === undefined) {
        this.SET_POPUP(true);
        this.popupSet = {};
        this.popupSet.popType = "warn";
        this.popupSet.popImg = "success.svg";
        this.popupSet.content = "영상파일이 존재하지 않습니다.";
        this.popupSet.cancelBtnText = null;
        this.popupSet.confirmBtnText = "확인";
      } else {
        const VideoUrl = video;
        window.open("https://admin.swallow.pro" + VideoUrl, "_blank");
      }
    },
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "로그아웃 되었습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    getMember() {
      this.FETCH_TRAINING_DATE(`?userId=${this.$route.query.userId}`).then(data => {
        console.log(data);
        const getList = [
          {
            question: 1,
            questionText: "회원명",
            answerResult: data.response.userName
          },
          {
            question: 2,
            questionText: "훈련일자",
            answerResultOpt: data.response.list
          }
        ];
        getList[1].answerResult = String(this.$route.query.date);
        this.member = getList;
      });
    }
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    transTitle() {
      return indexNum => {
        console.log("indexNum :", indexNum);
        let mutatedValue;
        if (indexNum === 0) {
          mutatedValue = "아침";
        } else if (indexNum === 1) {
          mutatedValue = "점심";
        } else if (indexNum === 2) {
          mutatedValue = "저녁";
        }
        return mutatedValue;
      };
    },
    transTxt() {
      return text => {
        if (text === "morning") {
          return "아침";
        } else if (text === "afternoon") {
          return "점심";
        } else if (text === "evening") {
          return "저녁";
        }
      };
    }
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    vSelect
  }
};
</script>
<style lang="scss" scoped>
.notification {
  p {
    @include initfontKor(
      $font-size: 24px,
      $line-height: 40px,
      $font-family: "AppleSDGothicNeoM00",
      $color: #292a2b,
      $letter-spacing: -0.6px,
      $text-align: left
    );
  }
}
.member-info-set {
  width: 340px;
  margin: 0 0 85px 0;
  .header {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 125px 1fr;
    margin: 0 0 1px 0;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      p {
        background: #f3f3f3;
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  .content {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 125px 1fr;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      display: flex;
      justify-content: center;
      align-items: center;
      .select-wrap {
        margin: 10px auto;
      }
      p {
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}
.video-record {
  margin: 0 0 105px 0;
  .video-wrap {
    width: 350px;
    margin: 0 auto 0 0px;
    &:first-of-type {
      margin: 0;
    }
    h3.title {
      margin: 0;
      background: #fd8b11;
      outline: 1px solid #fd8b11;
      margin: 0 0 1px 0;
      @include initfontKor(
        $font-size: 20px,
        $line-height: 34px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #fff,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
    .download-wrap {
      .download {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 120px 1fr;
        outline: 1px solid #acb3c9;
        margin: 1px 0 0 0;

        h3 {
          border-right: 1px solid #acb3c9;
          padding: 8px 0;
          @include initfontKor(
            $font-size: 19px,
            $line-height: 34px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
        span {
          display: block;
          outline: #acb3c9;
          margin: 8px;
          cursor: pointer;
          background: #f3f3f3;
          border: 1px solid #acb3c9;
          &:hover {
            background: #292a2b;
            border: 1px solid #292a2b;
            color: #fff;
          }
          @include initfontKor(
            $font-size: 19px,
            $line-height: 34px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
        &:first-of-type {
          margin: 0;
        }
      }
    }
  }
}
</style>
